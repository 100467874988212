#login-signup-form {
  background-image: url('https://images.unsplash.com/photo-1526386172983-95d2ae8a88a6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1331&q=80');
  background-size: cover;
  align-items: center;
  height: calc(100vh - 80px);
  width: 100vw;
  .form{
    background: #1c2336;
    border: #d8b586 solid 3px;
    border-radius: 10px;
    .nav {
      width: 100%;
      .nav-item {
        cursor: pointer;
        a {
          background: transparent;
          color: white;

          &:hover,&:active {
            color: #d8b586
          }

        }
      }
    }

  }
}
