@import url('https://unpkg.com/leaflet@1.5.1/dist/leaflet.css');
#root{
  box-sizing: border-box;
  & > div{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    box-sizing: border-box;
  }
}

.form-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 40vw;
    min-width: 350px;
    max-width: 400px;
    height: fit-content;
    .container {
      margin: 20px 10px;
      form {
        display: flex;
        width: 100%;
        height: fit-content;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        color: #d8b586
      }
    }
  }

}
